<template>
    <v-dialog v-model="dialog"
                persistent
                dark
                max-width="600px">  
        <v-card>
            <v-card-title>
                <span class="text-h5">Change Password</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Password"
                                          type="password"
                                          v-model="pass" 
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Confirm Password"
                                          type="password"
                                           v-model="confirmPass" 
                                          required></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <div style="color:red; height:20px; font-weight:bold;">{{error}}</div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false"> Cancel </v-btn>
                <v-btn color="red" text @click="updatePassword()"> Update Password </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import { userService } from '../services/user-service';
    import { notificationHooks } from '../shared/vue-mixin';

    export default {
        name: 'ChangePasswordForm',

        /* COMPONENT DATA  */
        data() {
            return {
                dialog: false,
                pass: '',
                confirmPass: '',
                error: '',
            };
        },

        /* MIXINS */
        mixins: [notificationHooks],

        /* COMPONENT METHODS  */
        methods: {

            async updatePassword() {
                this.error = "";

                //Reject invalid input
                if (!this.validate()) return;

                var updatePasswordResponse = await userService.updatePassword(this, { password: `${this.pass}` });

                if (updatePasswordResponse.success) {
                    this.showSuccessMessage("CryptoTaxSpace", "Password has been successfully updated.");
                    this.dialog = false;
                }
                else {
                    this.error = updatePasswordResponse.error.replace(/['"]+/g, '');
                }
          
            },

            validate() {

                //Validation
   
                if (this.pass !== this.confirmPass) {
                    this.error = "Passwords do not match";
                    return false;
                }

                if (this.pass.trim() === "") {
                    this.error = "Password cannot be empty";
                    return false;
                }

                if (!this.validatePassword(this.pass)) {
                    this.error = "Password length must be between 6 - 64 characters";
                    return false;
                }

                return true;
            },

            validatePassword(password) {
                return /^.{6,64}$/.test(password);
            },

            /* PUBLIC METHODS  */
            ShowModal() {
                this.error = "";
                this.pass = "";
                this.confirmPass = "";
                this.dialog = true;
            }
        },
    };
</script>